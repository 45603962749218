import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { api } from '../api';
import { Spinner } from '../components/spinner';
import { InboxArrowDownIcon } from '@heroicons/react/24/outline';
import { Subheading } from '../components/ui/heading';
import { Button } from '../components/ui/button';
import { Divider } from '../components/ui/divider';
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from '../components/ui/description-list';
import { format } from 'date-fns';
import { CreateRefundRequestDto } from '../generated';
import { FormEvent, useRef, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '../components/ui/dialog';
import { Field } from '../components/ui/fieldset';
import { Textarea } from '../components/ui/textarea';
import { handleinteractions } from '../helpers';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const ReceiptView = () => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');

  const componentRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();
  const { id } = useParams();

  const { data: receipt } = useQuery({
    queryKey: ['receipt', id],
    queryFn: async () => {
      const { data } = await api.paymentsControllerFindOneForUser({ paymentId: id as string });
      return data;
    },
  });

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: async (data: CreateRefundRequestDto) =>
      api.refundRequestsControllerCreate({
        createRefundRequestDto: data,
      }),
    onSuccess: () => {
      setOpen(false);
      queryClient.invalidateQueries(['receipt', id]);
    },
  });

  const hasRefundRequest = receipt && receipt?.refundRequests.length > 0;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (hasRefundRequest) return;

    mutate({
      paymentId: id as string,
      reason,
    });
  };

  const formattedDate = receipt && format(new Date(receipt.createdAt), 'HH:mm – EE dd MMMM, yyyy');

  if (!receipt)
    return (
      <div className="w-full min-h-dvh grid place-content-center">
        <Spinner />
      </div>
    );

  const priceData = {
    amount: ((receipt.amount * 0.8) / 100).toLocaleString('sv-SE') + ' ' + receipt.currency,
    vat: ((receipt.amount * 0.2) / 100).toLocaleString('sv-SE') + ' ' + receipt.currency,
    total: (receipt.amount / 100).toLocaleString('sv-SE') + ' ' + receipt.currency,
  };

  const DownloadButton = () => (
    <button
      {...handleinteractions(() => {
        generateA4PDF();
      })}
      className="size-10 bg-white/5 flex items-center justify-center rounded-full"
    >
      {<InboxArrowDownIcon className="!text-primary size-6" />}
    </button>
  );

  const generateA4PDF = () => {
    const input = componentRef.current;

    if (!input) return;

    html2canvas(input, { scale: 1.5 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 0.7);

      const pdf = new jsPDF('portrait', 'mm', 'a4');

      const pdfWidth = 210;
      const pdfHeight = 297;

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const widthRatio = pdfWidth / imgWidth;
      const heightRatio = pdfHeight / imgHeight;
      const scaleFactor = Math.min(widthRatio, heightRatio);

      const scaledWidth = imgWidth * scaleFactor;
      const scaledHeight = imgHeight * scaleFactor;

      pdf.setFillColor(255, 255, 255);
      pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

      pdf.addImage(
        imgData,
        'PNG',
        (pdfWidth - scaledWidth) / 2,
        (pdfHeight - scaledHeight) / 2,
        scaledWidth,
        scaledHeight,
        undefined,
        'FAST',
      );

      pdf.save(receipt.facility.name + '_easyjacket_A4_receipt.pdf');
    });
  };

  return (
    <>
      <section className="size-full overflow-y-scroll relative">
        <div className="flex flex-col items-end p-4">
          <div className="flex items-center gap-2">
            <span className="text-sm text-white">{t('receipt.download')}</span>
            <DownloadButton />
          </div>
        </div>
        <div
          ref={componentRef}
          className="bg-bgSecondary rounded-xl py-4 flex items-center justify-center flex-col gap-4 m-4"
        >
          <Subheading>{t('receipt.header')}</Subheading>
          <DescriptionList className="w-full px-4">
            <DescriptionTerm>{t('pricing.amount')}</DescriptionTerm>
            <DescriptionDetails>{priceData.amount}</DescriptionDetails>

            <DescriptionTerm>{t('pricing.vat')}</DescriptionTerm>
            <DescriptionDetails>{priceData.vat}</DescriptionDetails>

            <DescriptionTerm>{t('pricing.total')}</DescriptionTerm>
            <DescriptionDetails>{priceData.total}</DescriptionDetails>
          </DescriptionList>
          <Divider />

          <DescriptionList className="w-full px-4">
            <DescriptionTerm>{t('general.facility')}</DescriptionTerm>
            <DescriptionDetails>{receipt.facility.name}</DescriptionDetails>

            <DescriptionTerm>{t('general.date')}</DescriptionTerm>
            <DescriptionDetails>{formattedDate}</DescriptionDetails>

            <DescriptionTerm>Payment method</DescriptionTerm>
            <DescriptionDetails>{receipt.paymentMethod}</DescriptionDetails>

            <DescriptionTerm>Referens</DescriptionTerm>
            <DescriptionDetails>{receipt.id}</DescriptionDetails>

            <DescriptionTerm>Status</DescriptionTerm>
            <DescriptionDetails>{receipt.status}</DescriptionDetails>
          </DescriptionList>
        </div>
        <div className="bg-bgSecondary rounded-xl p-4 flex flex-col items-center justify-center gap-8 m-4">
          <Button
            color="blue"
            className="w-full"
            {...handleinteractions(() => setOpen(true))}
            disabled={hasRefundRequest}
          >
            {hasRefundRequest ? t('receipt.refundRequested') : t('refunds.header')}
          </Button>
        </div>
      </section>
      <Dialog open={open} onClose={setOpen}>
        <DialogTitle>{t('refunds.header')}</DialogTitle>
        <DialogDescription>{t('refunds.message')}</DialogDescription>
        <form onSubmit={handleSubmit}>
          <DialogBody>
            <Field>
              <Textarea
                required
                rows={4}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Field>
          </DialogBody>
          <DialogActions>
            <Button plain {...handleinteractions(() => setOpen(false))}>
              {t('refunds.cancel')}
            </Button>
            <Button color="blue" type="submit">
              {t('refunds.submit')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
