import {
  ArrowRightIcon,
  CalendarIcon,
  ClockIcon,
  InformationCircleIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Button } from '../components/events/button';
import { Card } from '../components/events/card';
import { EventDetails } from '../components/events/event-details';
import { EventHeader } from '../components/events/event-header';
import { LocationSection } from '../components/events/location-section';
import { TicketSection } from '../components/events/ticket-section';

export const EventView = () => {
  const [selectedTicketCount, setSelectedTicketCount] = useState(0);
  const [, setCheckoutOpen] = useState(false);
  //   const [, setPurchaseComplete] = useState(false);

  // Mock event data
  const event = {
    id: '1',
    title: 'Summer Music Festival 2023',
    date: 'August 15, 2023',
    time: '4:00 PM - 11:00 PM',
    location: 'Central Park, New York',
    image:
      'https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?q=80&w=2070&auto=format&fit=crop',
    description:
      'Join us for the ultimate summer music experience featuring top artists from around the world. Enjoy a day filled with amazing performances, great food, and unforgettable memories.',
    longDescription:
      "The Summer Music Festival returns for its 10th anniversary with an incredible lineup of artists spanning multiple genres. From indie rock to electronic dance music, there's something for everyone at this year's festival.\n\nIn addition to the amazing music, attendees can enjoy a variety of food vendors, art installations, and interactive experiences throughout the venue. This is more than just a concert – it's a celebration of music, art, and community.",
    organizer: 'Eventopia Productions',
    ticketTypes: [
      { id: '1', name: 'General Admission', price: 89.99, available: true },
      { id: '2', name: 'VIP Experience', price: 199.99, available: true },
      { id: '3', name: 'Early Entry Package', price: 129.99, available: false },
    ],
    locationDetails: {
      address: 'Central Park, New York, NY 10022',
      coordinates: { lat: 40.7812, lng: -73.9665 },
      parkingInfo: 'Limited parking available on-site. Public transportation recommended.',
      publicTransport: 'Subway lines A, B, C, and D stop at Columbus Circle station.',
    },
  };

  //   const handleCheckoutComplete = () => {
  //     setPurchaseComplete(true);
  //     setSelectedTicketCount(0);
  //   };

  return (
    <div className="size-full overflow-y-scroll relative min-h-screen bg-gray-50">
      <EventHeader
        title={event.title}
        image={event.image}
        date={event.date}
        time={event.time}
        location={event.location}
      />

      <div className="container mx-auto px-4 py-6 space-y-8 max-w-3xl">
        {/* Quick summary */}
        <div className="flex flex-wrap gap-3 justify-between">
          <div className="flex items-center text-sm gap-1.5">
            <CalendarIcon className="h-4 w-4 text-purple-700" />
            <span>{event.date}</span>
          </div>
          <div className="flex items-center text-sm gap-1.5">
            <ClockIcon className="h-4 w-4 text-purple-700" />
            <span>{event.time}</span>
          </div>
          <div className="flex items-center text-sm gap-1.5">
            <MapPinIcon className="h-4 w-4 text-purple-700" />
            <span>{event.location}</span>
          </div>
          <div className="flex items-center text-sm gap-1.5">
            <InformationCircleIcon className="h-4 w-4 text-purple-700" />
            <span>By {event.organizer}</span>
          </div>
        </div>

        {/* Navigation */}
        <Card className="overflow-hidden shadow-sm border-0">
          <div className="flex overflow-x-auto scrollbar-hide">
            <a
              href="#details"
              className="flex-1 py-3 px-4 text-center text-sm font-medium border-b-2 border-purple-700 text-purple-700"
            >
              Details
            </a>
            <a
              href="#tickets"
              className="flex-1 py-3 px-4 text-center text-sm font-medium border-b-2 border-transparent hover:text-gray-700"
            >
              Tickets
            </a>
            <a
              href="#location"
              className="flex-1 py-3 px-4 text-center text-sm font-medium border-b-2 border-transparent hover:text-gray-700"
            >
              Location
            </a>
          </div>
        </Card>

        {/* Main content */}
        <EventDetails description={event.description} longDescription={event.longDescription} />

        <TicketSection
          ticketTypes={event.ticketTypes}
          selectedCount={selectedTicketCount}
          setSelectedCount={setSelectedTicketCount}
        />

        <LocationSection location={event.locationDetails} />

        {/* Fixed bottom bar for checkout */}
        {selectedTicketCount > 0 && (
          <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg border-t p-4 z-10">
            <div className="container mx-auto max-w-3xl flex items-center justify-between">
              <div>
                <p className="text-sm font-medium">
                  {selectedTicketCount} ticket{selectedTicketCount !== 1 ? 's' : ''} selected
                </p>
                <p className="text-lg font-bold">
                  ${(selectedTicketCount * event.ticketTypes[0].price).toFixed(2)}
                </p>
              </div>
              <Button
                className="text-white bg-purple-700 hover:bg-purple-800"
                onClick={() => setCheckoutOpen(true)}
              >
                Continue to checkout
                <ArrowRightIcon className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </div>
        )}
      </div>

      {/* <CheckoutModal
        open={checkoutOpen}
        onOpenChange={setCheckoutOpen}
        ticketCount={selectedTicketCount}
        ticketPrice={event.ticketTypes[0].price}
        onComplete={handleCheckoutComplete}
      /> */}
    </div>
  );
};
