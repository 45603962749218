import { ShareIcon } from '@heroicons/react/24/outline';
import { Button } from './button';

interface EventHeaderProps {
  title: string;
  image: string;
  date: string;
  time: string;
  location: string;
}

export const EventHeader = ({ title, image }: EventHeaderProps) => {
  return (
    <div className="relative">
      {/* Hero image */}
      <div className="relative h-[40vh] min-h-[300px] w-full">
        <div className="absolute inset-0 bg-black/40 z-10" />
        <img src={image} alt={title} className="w-full h-full object-cover" />
        <div className="absolute top-4 right-4 z-20">
          <Button
            variant="outline"
            size="icon"
            className="rounded-full bg-white/30 backdrop-blur-sm border-0 hover:bg-white/50"
          >
            <ShareIcon className="h-5 w-5 text-white" />
          </Button>
        </div>
      </div>

      {/* Event title overlay */}
      <div className="relative bg-gradient-to-t from-black/80 to-transparent -mt-24 pt-10 px-4 pb-4 z-20">
        <h1 className="text-2xl md:text-3xl font-bold text-white">{title}</h1>
      </div>
    </div>
  );
};
