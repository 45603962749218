import { MinusIcon, PlusIcon, TicketIcon } from '@heroicons/react/24/outline';
import { Badge } from './badge';
import { Button } from './button';
import { Card } from './card';

interface TicketType {
  id: string;
  name: string;
  price: number;
  available: boolean;
}

interface TicketSectionProps {
  ticketTypes: TicketType[];
  selectedCount: number;
  setSelectedCount: (count: number) => void;
}

export const TicketSection = ({
  ticketTypes,
  selectedCount,
  setSelectedCount,
}: TicketSectionProps) => {
  const handleIncrement = () => {
    setSelectedCount(selectedCount + 1);
  };

  const handleDecrement = () => {
    if (selectedCount > 0) {
      setSelectedCount(selectedCount - 1);
    }
  };

  return (
    <section id="tickets" className="space-y-4 pt-2">
      <h2 className="text-xl font-bold flex items-center gap-2">
        <TicketIcon className="h-5 w-5 text-purple-700" />
        Tickets
      </h2>

      <div className="space-y-3">
        {ticketTypes.map((ticket) => (
          <Card key={ticket.id} className={`p-4 ${!ticket.available ? 'opacity-70' : ''}`}>
            <div className="flex justify-between items-center">
              <div>
                <h3 className="font-medium">{ticket.name}</h3>
                <p className="text-lg font-bold">${ticket.price.toFixed(2)}</p>
                {!ticket.available && (
                  <Badge variant="outline" className="mt-1">
                    Sold Out
                  </Badge>
                )}
              </div>

              {ticket.available ? (
                <div className="flex items-center space-x-3">
                  <Button
                    variant="outline"
                    size="icon"
                    className="h-8 w-8 rounded-full"
                    onClick={handleDecrement}
                    disabled={selectedCount === 0}
                  >
                    <MinusIcon className="h-4 w-4" />
                  </Button>
                  <span className="w-6 text-center">{selectedCount}</span>
                  <Button
                    variant="outline"
                    size="icon"
                    className="h-8 w-8 rounded-full"
                    onClick={handleIncrement}
                  >
                    <PlusIcon className="h-4 w-4" />
                  </Button>
                </div>
              ) : (
                <Button variant="outline" className="opacity-50" disabled>
                  Unavailable
                </Button>
              )}
            </div>
          </Card>
        ))}
      </div>
    </section>
  );
};
