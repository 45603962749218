import { CircleStackIcon, MapPinIcon, PhoneXMarkIcon } from '@heroicons/react/24/outline';
import { Card } from './card';

interface LocationDetails {
  address: string;
  coordinates: { lat: number; lng: number };
  parkingInfo: string;
  publicTransport: string;
}

interface LocationSectionProps {
  location: LocationDetails;
}

export const LocationSection = ({ location }: LocationSectionProps) => {
  return (
    <section id="location" className="space-y-4 pt-2 pb-20">
      <h2 className="text-xl font-bold flex items-center gap-2">
        <MapPinIcon className="h-5 w-5 text-purple-700" />
        Location
      </h2>

      <Card className="overflow-hidden">
        {/* Map placeholder - In a real app, this would be an embedded map */}
        <div className="h-[200px] bg-gray-200 flex items-center justify-center">
          <div className="text-center">
            <MapPinIcon className="h-8 w-8 mx-auto text-gray-500" />
            <p className="text-sm text-gray-600 mt-2">Map location for {location.address}</p>
          </div>
        </div>

        <div className="p-4 space-y-4">
          <h3 className="font-medium text-lg">{location.address}</h3>

          <div className="space-y-3">
            <div className="flex gap-3">
              <PhoneXMarkIcon className="h-5 w-5 mt-0.5 text-purple-700 flex-shrink-0" />
              <div>
                <h4 className="font-medium">Parking Information</h4>
                <p className="text-sm text-gray-600">{location.parkingInfo}</p>
              </div>
            </div>

            <div className="flex gap-3">
              <CircleStackIcon className="h-5 w-5 mt-0.5 text-purple-700 flex-shrink-0" />
              <div>
                <h4 className="font-medium">Public Transportation</h4>
                <p className="text-sm text-gray-600">{location.publicTransport}</p>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </section>
  );
};
