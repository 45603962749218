import { useState } from 'react';
import { Button } from './button';
import { Card } from './card';

interface EventDetailsProps {
  description: string;
  longDescription: string;
}

export const EventDetails = ({ description, longDescription }: EventDetailsProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <section id="details" className="space-y-4">
      <h2 className="text-xl font-bold">Event Details</h2>
      <Card className="p-4 space-y-3">
        <p className="text-gray-700">{expanded ? longDescription : description}</p>
        {longDescription !== description && (
          <Button
            variant="ghost"
            className="text-purple-700 hover:text-purple-800 hover:bg-purple-50 p-0 h-auto"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Read less' : 'Read more'}
          </Button>
        )}
      </Card>
    </section>
  );
};
